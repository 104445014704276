import { Component, OnInit, Inject, PLATFORM_ID, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Message, SelectItem } from 'primeng//api';
import { AppSettings } from '../../../shared/app.settings';
import { UserCommonService } from '../../../shared/services/user-common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-donor-details-form',
  templateUrl: './donor-details-form.component.html',
  styleUrls: ['./donor-details-form.component.scss']
})
export class DonorDetailsFormComponent implements OnInit {
  @Input() TransactionPercentage: any;  
  msgs: Message[] = [];
  amount = '';
  donationDetailsForm: any;
  denominations: SelectItem []=[];
  denomination: any;
  userId: any;
  campaignId: any;
  token: any;
  mediaType: any;
  showCustom = false;
  anonymous = false;
  isPlatformBrowser: any;
  additionalCharges =true;
  transactionCharges: any;
  totalDenominationCharges = 0;
  checkboxDisabled: boolean = true;
  totalDenominationAmount: any;
  showCheckbox: boolean = false;
  donationsForm = {
    message: '',
    amount: '',
    email: '',
    name: '',
    donorContactNumber: ''
  };
  constructor(@Inject(PLATFORM_ID) platformId: Object,
    private formBuilder: FormBuilder,
    private commonService: UserCommonService,
    private router: Router,
    private route: ActivatedRoute,
    private commonBindingService: CommonBindingDataService
  ) {
    this.isPlatformBrowser = isPlatformBrowser(platformId);
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.campaignId = this.route.snapshot.params['c'];
    this.mediaType = this.route.snapshot.queryParamMap.get('mediaType');
  }

  ngOnInit() {

    this.denominations = [
      { label: '$50', value: '50' },
      { label: '$100', value: '100' },
      { label: '$250', value: '250' },
      { label: '$500', value: '500' },
      { label: '$1000', value: '1000' },
      { label: 'Custom', value: '' },
    ];

    this.commonService.donor = (this.commonService.donor) ? this.commonService.donor : this.donationsForm;


    this.donationDetailsForm = this.formBuilder.group({
      message: [this.commonService.donor.message, [Validators.maxLength(250)]],
      name: [{
        value: this.commonService.donor.name,
        disabled: this.anonymous
      }, [Validators.required]],
      donorContactNumber: [this.commonService.donor.donorContactNumber, [Validators.required, Validators.minLength(10)]],
      amount: [this.commonService.donor.amount, [Validators.required, Validators.min(1), Validators.pattern(AppSettings.NUMBER_PATTERN)]],
      email: [this.commonService.donor.email, [Validators.pattern(AppSettings.EMAIL_PATTERN)]],
    });
    this.transactionCharges=this.TransactionPercentage;
  }

  setAmount() {
    if (this.denomination === '') {
      this.showCustom = true;
    } else {
      this.showCustom = false;
    }
    this.donationDetailsForm.controls.amount.setValue(this.denomination);
    this.setCoverCharges();
  }

  onDonate() {
    this.commonService.donor = this.donationDetailsForm.value;
    if (this.anonymous) {
      this.commonService.donor.name = this.commonBindingService.getLabel('lbl_anonymous');
    }
    if (parseInt(this.donationDetailsForm.controls.amount.value, 10) <= 0) {
      this.msgs = [];
      this.msgs.push({
        severity: 'error',
        summary: this.commonBindingService.getLabel('err_summary'),
        detail: this.commonBindingService.getLabel('err_invaid_amount')
      });
    } else {
      this.router.navigate(['/payment/' + this.campaignId], {
        queryParams: {
          userId: this.userId,
          campaignId: this.campaignId,
          mediaType: this.mediaType,
          serviceCharges: this.totalDenominationCharges
        }
      });
    }

  }

  amountUpdated() {
    this.denomination = this.donationDetailsForm.controls.amount.value;
    this.setCoverCharges();
  }

  onAnonymousSelected(e) {
    const name = (this.anonymous) ? 'Anonymous' : '';
    this.donationDetailsForm.controls.name.setValue(name);
    // const donorContactNumber = (this.anonymous) ? 'Anonymous' : '';
    // this.donationDetailsForm.controls.donorContactNumber.setValue(donorContactNumber);
    // if (e.checked) {
    //   this.donationDetailsForm.controls['donorContactNumber'].clearValidators(); // Remove validation
    //   this.donationDetailsForm.controls['donorContactNumber'].updateValueAndValidity();
    // }else {
    //   this.donationDetailsForm.controls['donorContactNumber'].setValidators([Validators.required, Validators.minLength(10)]); // Restore validation
    //   this.donationDetailsForm.controls['donorContactNumber'].updateValueAndValidity();
    // }
  }
 
  setCoverCharges()
  {
    if(this.denomination != undefined && this.showCheckbox === false)
    {
      this.checkboxDisabled = false;
      this.totalDenominationCharges=(this.transactionCharges / 100) * this.denomination;
      this.totalDenominationAmount = (parseInt(this.denomination) + this.totalDenominationCharges);
    }  
  }
  coverChargesSelected($event:any)
  {
      if($event.checked === false){
        this.showCheckbox = true;
        this.totalDenominationCharges=0;
        this.totalDenominationAmount = this.denomination;
      }
      else{
        this.showCheckbox = false;
        this.totalDenominationCharges=(this.transactionCharges / 100) * this.denomination;
        this.totalDenominationAmount = (parseInt(this.denomination) + this.totalDenominationCharges);
      }
  }
}
